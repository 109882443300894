

$primary: #00BCD4; 
$primaryDark:#0097A7;
$primaryLight: #B2EBF2;

$accent: #FFC107;
$primaryText: #212121;
$secondaryText:#757575;
$deviderColor:#BDBDBD;
$text:#FFFFFF;

$danger : #EE6352;

$lightBlue: #58A4B0;
$blue: #335c67;
$darkBlue: #0B3954;
$yellow: #F6BD60;
$black: #1B1B1E;
$grey: #aaaaaa;



//mediaquery breakpoints
$breakpoint-tablet: 768px;
$breakpoint-tablet: 420px;