@import '../../colors';

a:link{
    text-decoration: none;
}
.projects{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding-top: 3em;
    .wrapper{
        z-index: 99999;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        &:nth-child(odd) > .project > .title{
            justify-self: left;
        }
        &:nth-child(even) > .project > .title{
            justify-self: right;
            background-color: $yellow;
        }
        .title{
            color: $black;
            width: 80%;
            text-align: center;
        }
        .project{
            display: flex;
            flex-direction: column;
            width: 80vw;
            min-height: 20vw;
            margin: 2em;
            border: 3px $lightBlue solid;
            border-radius: 3px;
            transition: 0.3s;
            &:hover{
                border: 6px $primaryDark solid;
                border-radius: 5px;
            }
            .title{
                color: $primaryDark;
                font-size: xx-large;
                display: flex;
                padding: 0 0.8em;
            }
            .content{
                display: flex;
                flex-direction: row;
                justify-content: center;                
                flex-wrap: wrap;
                padding: 0 0.8em;
                @media (max-width: $breakpoint-tablet) {
                    &{
                        flex-direction: column-reverse;
                        align-items: center;
                        align-content: space-around;
                    }
                }
                .text{
                    color: $primaryText;
                    text-justify: distribute;
                    min-width: 55%;
                    width: 55%;
                    height: fit-content;
                    padding: 1em;                 
                    &::first-letter{
                        font-size: 2rem;
                        font-variant-caps: all-petite-caps;
                        color: $primaryDark;
                    }
                    @media (max-width: $breakpoint-tablet) {
                        &{
                            width: 90%;
                        }
                    }
                }
                .project-img{
                    min-width: 248px;
                    width: 39%;
                    display: flex;
                    justify-content: center;
                    border: 3px $yellow solid;
                    border-radius: 3px;
                    margin-bottom: 1em;
                    img{
                        width: 100%;
                        height: 100%;
                        margin: auto;
                        
                    }
                    @media (max-width: $breakpoint-tablet) {
                        &{
                            margin-bottom: 0;
                            width: 90%;
                        }
                    }
                }
            }            
        }
    }
}