@import '../../colors';

.home{
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    .top{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 100vh;
        // background-color: $primaryText;
        .wrapper{
            display: flex;
            justify-content: space-around;
            .box, .img-box{
            min-width: 30vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 1.5em;
                .hello{
                    max-width: 50vw;
                    font-size: 6vw;
                    font-weight: 900;
                    .name{
                        color: $primaryDark;
                        white-space: pre;
                    }
                }
                img{
                    padding-top: 3rem;
                    width: 30vw;
                    height: auto;
                }
                .slogan{
                    font-size: larger;
                    color: $secondaryText;
                    &::first-letter{
                        font-size: 3rem;
                        font-variant-caps: all-petite-caps;
                        color: $primaryDark;
                    }
                }
                .socials{
                    .socials-link{
                        color: $yellow;
                        padding-right: 1em;
                    }
                }
            }
        }
        }
        .arrow-down{
            width: 8em;
            height: 8em;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            .arrow-icon {
                width: 6em;
                height: 6em;
                transition: 0.3s;
                &:hover{
                    width: 7em;
                    height: 7em;
                }
            }
        }
    }
    
    @media screen and (max-width: $breakpoint-tablet) {
        .img-box{
            display: none !important;
        }
        .content > img{
            display: none;
        }
    }
}