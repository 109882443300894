@import '../../colors';

.nav-bar{
    width: 100vw;
    min-width: 320px;
    height: 3em;
    display: flex;
    justify-content: center;
    position: fixed;
    background-color: white;
    z-index: 999999;
    &::after{
        content:"";
        background: linear-gradient(to left, white 0%, rgba($primaryLight, 0.8) 50%, white 100%);
        height: 5px;
        width: 100%;
        position: absolute;
        top: 3em;
    }
    .icon{        
        display: none;
    }
    
    .nav-left{
        display: flex;        
        align-items: center;
        justify-content: flex-start;
        min-width: 60vw;
        .title{
            color: $primaryDark;
            font-variant: small-caps;
            font-size: xx-large;
            font-weight: 500;
        }
    }
    .nav-right{
        display: flex;        
        align-items: center;
        align-self: right;
        justify-content: space-around;
        min-width: 30vw;
        .item{
            padding: 0.3rem;
            color: $secondaryText;
            font-variant: small-caps;
            font-size:x-large;
            font-weight: 500;
        }
    }
    @media screen and (max-width: $breakpoint-tablet) {
        .icon{
            position: absolute;
            color: $grey;
            display: flex;            
            right: 0;
            align-self: center;
            padding: 0.8rem;
            padding-right: 1.5em;
            font-variant: small-caps;
        }
        .nav-left{
            justify-content: center;
        }
        .nav-right{
            display: none;
        }
    }
}
.mobile-nav{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;    
    background-color: white;
    width: 100%;
    height: 3em;
    padding-top: 3em;
    z-index: 999998;
    .item{
        min-width: 4.5em;
        color: $yellow;
        font-variant: small-caps;
        font-size: large;
        font-weight: 500;
        text-align: center;
        
        &:not(:first-child):not(:last-child){            
            border-left: 2px $yellow solid;
            border-right: 2px $yellow solid;
            margin-left: 1em;
        }   
    }
}