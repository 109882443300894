@import '../../colors';

.project{
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    .top{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 30vh;
        .wrapper{
            display: flex;
            // justify-content: space-around;
            .title{
                color: $primaryDark;
                font-size: 3rem;
                justify-self: flex-start;
                text-align: center;
            }
        }
    }
    .wrapper{
        display: flex;
        justify-content: center;
        h1{
            margin: 0;
            color: $secondaryText;
            margin-bottom: 0.5rem;
        }
        .description{
            width: 80%;
            p{
                margin: 0;
                &::first-letter{
                    font-size: 3rem;
                    font-variant-caps: all-petite-caps;
                    color: $primaryDark;
                }
            }
        }
        .image-box{
            width: 80%;            
            .images{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .image{
                    width: 45%;
                    min-width: 18rem;
                    margin-bottom: 1em;
                    img{
                        border: 3px $yellow solid;
                        border-radius: 3px;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                    p{
                        color: $secondaryText;
                        font-style: italic;
                        margin: 0;
                        &::before,  &::after{
                            content: '"';
                            font-size: 1.5rem;
                            color: $primaryDark;
                        }
                    }
                }
            }
        }
        
    }
}