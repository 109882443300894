@import '../../colors';

.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 30vh;
        .title{
            max-width: 50vw;
            font-size: 5.5vw;
            font-weight: 900;
            text-align: center;
            .name{
                color: $primaryDark;
            }
        }
    }
    .wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        h2{
            color: $secondaryText;
        }
    }
    .text{
        color: $primaryText;
        &::first-letter{
            font-size: 3rem;
            font-variant-caps: all-petite-caps;
            color: $primaryDark;
        }
    }
    h3{
        color: $yellow;
        &:hover{
            color: $primaryDark;
        }
    }
    .socials{
        .socials-link{
            color: $primaryDark;
        }
    }
    .skills{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        .icons{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin: 0 2%;
            a{
                margin-bottom: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: $yellow;
                .icon{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 6rem;
                    max-width: 1em;
                    .name{
                        font-size: 1rem;
                        text-align: center;
                    }
                    &:hover{
                        color: $primaryDark;
                    }
                }
            }
        }
        .skill-text{
            color: $primaryText;
            height: 30em;
            text-align: center;
            align-self: center;
            width: 80%;
            &::first-letter{
                font-size: 3rem;
                font-variant-caps: all-petite-caps;
                color: $primaryDark;
            }
        }
        &:nth-child(odd){
            color: $yellow;
        }
        &:nth-child(even){
            color: $yellow;
        }
        
    }
}